<template>
  <v-container class="d-flex flex-row flex-nowrap login-page-container pa-0" fluid>
    <div class="image-login-container">
      <v-img class="login-image" :lazy-src="require('@/Assets/Images/Interface/planete-online-agencewaow-1949-64p.webp')" :src="require('@/Assets/Images/Interface/planete-online-agencewaow-1949-1680x960.webp')" alt=""></v-img>
    </div>
    <div class="left-side">
       <!-- afficher le logo signatures-fete -->
       <div class="fete-image" v-if="loginImage">
        <v-img class="fete-image-img" :src="loginImage" alt=""></v-img>
      </div>

      <h1 class="title-po login-title white--text">{{ $t('home.title') }}</h1>
      <h2 class="subtitle-po login-text white--text">{{ $t('home.subtitle') }}</h2>
    </div>
    <div class="right-side d-flex flex-column align-center"  :class="{'elevation-12': ['xs','sm'].indexOf(breakpoints)!=-1, 'rounded': ['xs','sm'].indexOf(breakpoints)!=-1}">
      <div class="fill-height d-flex flex-column align-center justify-center" :class="{'ma-4': ['xs','sm'].indexOf(breakpoints)!=-1}">
        <login-form></login-form>
      </div>
      <div class="d-flex flex-column align-center text-center">
        <div class="mb-2 copyright">Copyright {{ copyrightDate }} PLANETE-ONLINE ©</div>
        <div class="mb-4 grey--text version">La connexion à l’espace nécessite l’utilisation de cookies. v {{ version }}</div>
      </div>
    </div>
  </v-container>
</template>

<script>

import Vuex from "vuex"
import {SVSAuthentication} from "@/Services/SupportVisionService";
import loginImage from "@/Assets/Images/Interface/signatures-fete.png";
import FetesService from "@/Services/SupportVision/FetesService";
import moment from "moment";

export default {
  name: "Login",
  components: {
    LoginForm: () => import("@/Components/Views/Login/LoginForm")
  },
  data() {
    return {
      currentDate: null,
      loginImage: null
    }
  },
  computed: {
    ...Vuex.mapState(["authenticationConfiguration"]),
    breakpoints() {
      return this.$vuetify.breakpoint.name
    },
    version() {
      return process.env.VUE_APP_VERSION
    },
    copyrightDate(){
      return moment().format('YYYY')
    }
  },
  async mounted() {
    this.currentDate = moment().format('YYYY-MM-DD');
    const fetes = await FetesService.getFetes();
    for (const f of fetes) {
      for (const l of f.fete.list) {
        // si dns est dans la liste des clients de la fête 
        if (l.name === this.getDns()) {
          // si la date actuelle est comprise entre la date de début et la date de fin
          if (moment(this.currentDate).isBetween(f.fete.start, f.fete.end)) {
            this.loginImage = f.fete.newLogoPreview;
          }
        } 
      }
    }
  },
  async created() {
    // Chargement de la langue enregistrée par l'utilisateur ou paramétrée sur planete-online si elle n'existe pas
    let authConfigurations = await SVSAuthentication.getAuthenticationConfigurationLite()
    let dns = this.getDns()
    let configFound = authConfigurations.find(config => config.client == dns && config.active)
    this.$store.commit("setConfig", configFound)
    this.changeLang(navigator.language.split("-")[0] || 'en', false)
  }
}
</script>

<style scoped>
  .login-page-container {
    background: black;
  }

  .image-login-container {
    width: 100%; height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
  }

  .login-image {
    -webkit-transform: scaleX(-1.05);
    transform: scaleX(-1.05);
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .right-side {
    background: rgb(239,239,239);
    background: linear-gradient(180deg, rgba(239,239,239,1) 0%, rgba(215,229,238,1) 100%);
    opacity: 0.95;
    width: 45%;
    height: 100vh;
    z-index: 2000;
  }

  .left-side {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: rgba(0,0,0,.5);
    z-index: 1000;
  }

  .login-title {
    text-shadow: 1px 1px 8px rgba(0,0,0,.6);
    margin-bottom: 1.5em;
    margin-top: 0;
    text-align: center;
  }
  .login-text {
    margin-left:4em; margin-right:4em;
    text-align: center;
    text-shadow: 1px 1px 5px rgba(0,0,0,.5);
  }

  .fete-image {
    width: 45%;
    /* height: 200px; */
    max-height: 250px;
    position: absolute;
    top: 2%; 
    animation: slideIn 2s ease-in-out; 
    /* shadow */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  }

  .fete-image-img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    opacity: 0.9;
    object-fit: cover;
  }

  @keyframes slideIn {
    0% {
      /* bottom: -100%;  */
      opacity: 0;
    }
    50% {
      /* bottom: 0;  */
      opacity: 0.5;
    }
    100% {
      /* bottom: 0;  */
      opacity: 0.9;
    }
  }


  .copyright {
    font-size: .7em;
  }

  .version {
    font-size: .6em;
  }


  @media (max-width: 599px) {
    .login-page-container {
      flex-direction: column !important;
    }
    .right-side {
      margin: 2.5em auto auto auto;
      width: 75%;
      height: auto;
    }
    .left-side {
      height: 96px;
    }
    .login-title {
      font-size: 1em;
      margin-bottom: .3em;
    }
    .login-text {
      font-size: .7em;
    }
  }

  @media (min-width: 599px) and (max-width: 959px) {
    .login-page-container {
      flex-direction: column !important;
    }
    .right-side {
      margin: 1em auto auto auto;
      width: 66%;
      height: auto;
    }
    .left-side {
      height: 112px;
    }
    .login-title {
      margin-bottom: 0;
      font-size: 1.2em;
    }
    .login-text {
      font-size: .9em;
    }
  }

  @media (min-width: 959px) and (max-width: 1263px) {
    .login-title {
      font-size: 3em;
    }
    .login-text {
      font-size: 2em;
    }
  }

  @media (min-width: 1263px) and (max-width: 1903px) {
    .login-title {
      font-size: 4em;
    }
    .login-text {
      font-size: 2em;
    }
  }

  @media (min-width: 1903px) {}


</style>


